import React, {useState} from "react";
import {Routes, Route } from 'react-router-dom';
import DigitalArts from "./managers/arts/Arts";
import WebApps from './managers/webDevelopment/WebDevelopment.js';
import NotFound from './pages/NotFound';
import ProtectedRoute from "./ProtectedRoute";
import Blogs from './managers/Blogs';
import WorkExperience from './managers/experience/Experience.js';
import Education from './managers/education/Education.js';
import GameDevelopment from './managers/gameDevelopment/GameDevelopment.js';
import GameDesign from './managers/gameDesign/GameDesign.js';
import GameDirector from './managers/gameDirector/GameDirector.js';
import Notification from './Notification.js';
import MediaDesign from './managers/mediaDesign/MediaDesign.js';
import About from './managers/about/About.js';
import Skills from './managers/skills/Skills.js';
import Languages from './managers/languages/Languages.js';
import Awards from './managers/awards/Awards.js';

import Certifications from './managers/certifications/Certifications.js'
import Clean from './CleanUp';

function Dashboard() {

  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, type) => {
    const id = Date.now();
    setNotifications([...notifications, { id, message, type }]);
  };

  const removeNotification = (id) => {
    setNotifications(notifications.filter(notif => notif.id !== id));
  };

  return (
    <div className="dashboard ">
      {/* <ResponsiveNavigation /> */}
      
      <div className="main-content">
     
      <Routes>
      <Route path="digitalarts" element= {<ProtectedRoute><DigitalArts addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="web-developer" element= {<ProtectedRoute><WebApps addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="blogs" element= {<ProtectedRoute><Blogs addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="work-experience" element= {<ProtectedRoute><WorkExperience addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="media-design" element= {<ProtectedRoute><MediaDesign addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="game-developer" element= {<ProtectedRoute><GameDevelopment addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="game-design" element= {<ProtectedRoute><GameDesign addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="game-director" element= {<ProtectedRoute><GameDirector addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="skills" element= {<ProtectedRoute><Skills addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="certifications" element= {<ProtectedRoute><Certifications addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="languages" element= {<ProtectedRoute><Languages addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="awards" element= {<ProtectedRoute><Awards addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="education" element= {<ProtectedRoute><Education addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="about" element= {<ProtectedRoute><About addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="clean" element= {<ProtectedRoute><Clean addNotification={addNotification} /></ProtectedRoute>}/>
      </Routes>
      </div>
      <div className="notification-stack">
  {notifications.map(notif => (
    <Notification 
      key={notif.id} 
      message={notif.message} 
      type={notif.type}
      onClose={() => removeNotification(notif.id)}
    />
  ))}
</div>
    </div>
  );
}

export default Dashboard;
