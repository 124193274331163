import React, { useState, useEffect } from "react";
import { Button, Form, Spinner, Row, Col, Alert, Container } from "reactstrap";
import { db, firebase } from "../../../firebase";
import RichEditor from "../../forms/RichEditor"; // Rich Text Editor for multiline text

function About({ addNotification }) {
  const [about, setAbout] = useState("");
  const [passion, setPassion] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const firestoreCollection = "about"; // The Firestore collection for this page
  const documentId = "personalInfo"; // The document ID for this page

  // Prefill the form with existing data from Firestore or create a new document
  useEffect(() => {
    const fetchAboutData = async () => {
      setIsLoading(true);
      try {
        const docRef = db.collection(firestoreCollection).doc(documentId);
        const doc = await docRef.get();

        if (doc.exists) {
          const data = doc.data();
          setAbout(data.description || "");
          setPassion(data.passion || "");
          setHobbies(data.hobbies || "");
        } else {
          // Create a new document if it doesn't exist
          await docRef.set({
            description: "",
            passion: "",
            hobbies: "",
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
          addNotification("Created a new document for your personal story.", "info");
        }
      } catch (error) {
        setError("Error fetching data. Please refresh.");
        console.error("Error fetching about data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAboutData();
  }, [firestoreCollection, addNotification]);

  // Update form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const updatedData = {
        description: about,
        passion,
        hobbies,
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await db.collection(firestoreCollection).doc(documentId).update(updatedData);
      setSuccessMessage("Details updated successfully!");
      addNotification("Details updated successfully", "success");
    } catch (error) {
      console.error("Error updating data:", error);
      setError("Error updating details. Please try again.");
      addNotification("Error updating details. Please try again.", "danger");
    } finally {
      setIsLoading(false);
      setTimeout(() => setSuccessMessage(""), 3000); // Clear success message after 3 seconds
    }
  };

  // Reset form to default values
  const handleReset = () => {
    setAbout("");
    setPassion("");
    setHobbies("");
  };

  return (
    <Container className="p-3 mt-4  modal-container">
      <h1 className="title text-center mb-4">Edit Personal Story</h1>

      <Form onSubmit={handleSubmit}>
        {error && <Alert color="danger">{error}</Alert>}
        {successMessage && <Alert color="success">{successMessage}</Alert>}

        <Row className="mt-3">
          <Col md={12}>
            <RichEditor
              label="About Me"
              value={about}
              onChange={setAbout}
              placeholder="Tell a bit about yourself..."
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <RichEditor
              label="Passion (Why I do this job)"
              value={passion}
              onChange={setPassion}
              placeholder="Share why you love your profession..."
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <RichEditor
              label="Hobbies"
              value={hobbies}
              onChange={setHobbies}
              placeholder="Tell people about your hobbies..."
            />
          </Col>
        </Row>

        <Row className="mt-4 justify-content-center">
          <Col md="auto">
            <Button
              className="mt-2"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <Spinner size="sm" /> : "Update"}
            </Button>
          </Col>
          <Col md="auto">
            <Button className="mt-2" color="secondary" onClick={handleReset}>
              Reset
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default About;
