import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { faUserGraduate, faBriefcase, faBellConcierge, faWrench, faAward, faPalette, faCode, faFolderClosed, faGamepad, faPencil, faCircleUser} from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Sidebar() {
  const location = useLocation();

  const isActive = (path) => location.pathname.includes(path);

  return (
    <div className="sidebar label">
      <ListGroup flush>
        <ListGroupItem className="label " tag="strong">Personal</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/about")} tag={Link} to="/dashboard/about"><FontAwesomeIcon icon={faCircleUser} />{"  "}About</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/education")} tag={Link} to="/dashboard/education"><FontAwesomeIcon icon={faUserGraduate} />{"  "}Education</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/work-experience")} tag={Link} to="/dashboard/work-experience"><FontAwesomeIcon icon={faBriefcase} />{"  "}Work Experience</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/skills")} tag={Link} to="/dashboard/skills"><FontAwesomeIcon icon={faWrench} />{"  "}Skills</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/certifications")} tag={Link} to="/dashboard/certifications"><FontAwesomeIcon icon={faBellConcierge} />{"  "}Certifications</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/languages")} tag={Link} to="/dashboard/languages"><FontAwesomeIcon icon={faPencil} />{"  "}Languages</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/awards")} tag={Link} to="/dashboard/awards"><FontAwesomeIcon icon={faAward} />{"  "}Awards</ListGroupItem>
      </ListGroup>

      <ListGroup flush>
        <ListGroupItem className="label " tag="strong">Portfolios</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/digitalarts")} tag={Link} to="/dashboard/digitalarts"><FontAwesomeIcon icon={faPalette} />{"  "}Arts</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/web-developer")} tag={Link} to="/dashboard/web-developer"><FontAwesomeIcon icon={faCode} />{"  "}Web Developer</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/media-design")} tag={Link} to="/dashboard/media-design"><FontAwesomeIcon icon={faFolderClosed} />{"  "}Media Design</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/game-developer")} tag={Link} to="/dashboard/game-developer"><FontAwesomeIcon icon={faGamepad} />{"  "}Game Developer</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/game-design")} tag={Link} to="/dashboard/game-design"><FontAwesomeIcon icon={faGamepad} />{"  "}Game Design</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/game-director")} tag={Link} to="/dashboard/game-director"><FontAwesomeIcon icon={faGamepad} />{"  "}Game Director</ListGroupItem>
      </ListGroup>

      <ListGroup flush>
        <ListGroupItem className="label " tag="strong">Blogs</ListGroupItem>
        <ListGroupItem className="label sidebar-item" action active={isActive("/dashboard/blogs")} tag={Link} to="/dashboard/blogs"><FontAwesomeIcon icon={faPencil} />{"  "}Blogs</ListGroupItem>
      </ListGroup>
    </div>
  );
}

export default Sidebar;
